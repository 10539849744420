<div class="login-wrapper">
    <div class="card">
      <div class="card-body login-card-body">
        <div class="form-wrapper">
            <form class="current-form" [formGroup]="this.registerForm!" (ngSubmit)="submitForm()">
                <div class="card-body" [ngSwitch]="wizardStep">
                    <div class="wizard-indicator">
                        <div class="step" [ngClass]="{'step-active': wizardStep >= 1 }"></div>                  <!-- Dados Pessoais -->
                        <div class="step-divider" [ngClass]="{'step-active-divider': wizardStep >= 2 }"></div>
                        <div class="step" [ngClass]="{'step-active': wizardStep >= 2 }"></div>   <!-- Dados Contato -->
                        <ng-container *ngIf="this.state?.tempOrder && this.state?.tempUser">         
                            <div class="step-divider" [ngClass]="{'step-active-divider': wizardStep >= 3 }"></div>
                            <div class="step" [ngClass]="{'step-active': wizardStep >= 3 }"></div>   <!-- Endereço -->
                        </ng-container>                     
                    </div>
                    <div class="form-group" *ngSwitchCase="1">
                        <input type="text" class="form-control" formControlName="name" id="name" placeholder="Nome">
                    </div>
                    <div class="form-group" *ngSwitchCase="1">
                        <input type="text" class="form-control" formControlName="lastname" id="lastname" placeholder="Sobrenome">
                    </div>
                    <div class="form-group" *ngSwitchCase="1">
                        <input type="password" class="form-control" formControlName="password" id="password" placeholder="Senha">
                    </div>
                    <div class="form-group" *ngSwitchCase="1">
                        <input type="password" class="form-control" formControlName="confirmpassword" id="confirmpassword" placeholder="Confirmar Senha">
                    </div>
                    <div class="form-group" *ngSwitchCase="1">
                        <button  type="button" class="btn btn-primary btn-block btn-elementar" [ngClass]="{ 'disable': !allowStep1 }" (click)="nextStep(1,allowStep1 )">Próximo</button>
                    </div>

                    <ng-container *ngSwitchCase="2">
                        <div class="form-group" *ngIf="this.state?.tempOrder && this.state?.tempUser">
                            <input type="number" class="form-control" formControlName="cpf" id="cpf" placeholder="CPF">
                        </div>
                        <div class="form-group" *ngSwitchCase="2">
                            <input type="email" class="form-control" formControlName="email" id="email" placeholder="Email">
                        </div>
                        <div class="form-group" *ngSwitchCase="2">
                            <input type="number" class="form-control" formControlName="phone" id="phone" placeholder="Telefone">
                        </div>

                        <div class="form-group" *ngIf="this.state?.tempOrder && this.state?.tempUser">
                            <button  type="button" class="btn btn-primary btn-block btn-elementar" [ngClass]="{ 'disable': !allowStep2 }" (click)="nextStep(2, allowStep2)">Próximo</button>
                        </div>

                        <div class="form-group" *ngIf="!(this.state?.tempOrder && this.state?.tempUser)">
                            <button  type="submit" class="btn btn-primary btn-block btn-elementar">Finalizar cadastro</button>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="3">
                        <ng-container *ngIf="this.state?.tempOrder && this.state?.tempUser">
                            <div class="row">
                                <div class="form-group col-12">
                                    <input type="text" class="form-control" formControlName="address_nickname" id="address_nickname" placeholder="Nomear endereço. Ex: Casa">
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-8">
                                    <input type="text" class="form-control" formControlName="street" id="street" placeholder="Logradouro">
                                </div>
                                <div class="form-group col-4">
                                    <input type="text" class="form-control" formControlName="number" id="number" placeholder="Número">
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-12">
                                    <input type="text" class="form-control" formControlName="complement" id="complement" placeholder="Complemento">
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-6">
                                    <input type="text" class="form-control" formControlName="neighborhood" id="neighborhood" placeholder="Bairro">
                                </div>
                                <div class="form-group col-6">
                                    <input type="text" class="form-control" formControlName="city" id="city" placeholder="Cidade">
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-12">
                                    <button  type="submit" class="btn btn-primary btn-block btn-elementar">Finalizar cadastro</button>
                                </div>
                            </div>
                        </ng-container>
                        
                        <!-- nickname: string;
                        cep: string;
                        street: string;
                        number: number;
                        complement: string;
                        aditionalInfo: string;
                        neighborhood: string;
                        city: string; -->
                        <!-- owner: User; -->
                        
                    </ng-container>

                    

                    <div *ngSwitchDefault> {{wizardStep}}</div>
                </div>
            </form>
        </div>
        <div class="login-splitter"></div>
        <div class="login-logo">
          <img class="logo-item" width="100%" src="../../../assets/images/NO_BG_BANNER_LOGO_LIGHT.png" alt="Fotons">
        </div>
      </div>
    </div>
</div>

