/* eslint-disable prettier/prettier */

import { Address } from "./Address";
import { BaseModel } from "./BaseModel";
import { Password } from "./Password";
import { Token } from "./Token";

export class User extends BaseModel {
  firstname?: string;
  lastname?: string;
  email?: string;

  token?: Token;
  password?: Password;
  address?: Address;
  roles?: any;
 

  constructor(firstname: string | null = null, lastname: string | null = null, email: string | null = null) {
   super();

   if (firstname) {
    this.firstname = firstname;
   }
   if (lastname) {
    this.lastname = lastname;
   }
   if (email) {
    this.email = email;
   }
 }
}
