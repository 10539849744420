<section class="content-fluid content-padding">
    <div class="row">
        <div class="col-md-10 offset-md-1">
            <div *ngIf="currentOrder" class="row checkout-item">
                <div class="col-md-12">
                    <div class="card">
                        <div class="row">
                            <div class="col-md-12 checkout-image-title">
                                <img src="../../../assets/images/fottons_preto.png" alt="" srcset="">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-9">
                            <div class="card">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="section-title-wrapper">
                                            <div class="title-item">
                                                Produtos
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-2 offset-md-6">
                                                <div class="value-wrapper-header">
                                                    Preço
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="amount-wrapper-header">
                                                    Quantidade
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="total-wrapper-header">
                                                    Total
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngFor="let currentProductItem of currentOrder.productsList" class="row">
                                            <div class="col-md-6">
                                                <div class="fotons-wrapper-box">
                                                    <div class="fotons-row">
                                                        <!-- {{currentProductItem | json}} -->
                                                        <div class="fotons-column">
                                                            <img [src]="currentProductItem.img1?.data" alt="" srcset="">
                                                        </div>
                                                        <div class="fotons-column">
                                                            <img [src]="currentProductItem.img2?.data" alt="" srcset="">
                                                        </div>
                                                        <div class="fotons-column">
                                                            <img [src]="currentProductItem.img3?.data" alt="" srcset="">
                                                        </div>
                                                        <div class="fotons-column">
                                                            <img [src]="currentProductItem.img4?.data" alt="" srcset="">
                                                        </div>
                                                    </div>
                                                    <div class="fotons-row">
                                                        <div class="fotons-column">
                                                            <img [src]="currentProductItem.img5?.data" alt="" srcset="">
                                                        </div>
                                                        <div class="fotons-column">
                                                            <img [src]="currentProductItem.img6?.data" alt="" srcset="">
                                                        </div>
                                                        <div class="fotons-column">
                                                            <img [src]="currentProductItem.img7?.data" alt="" srcset="">
                                                        </div>
                                                        <div class="fotons-column">
                                                            <img [src]="currentProductItem.img8?.data" alt="" srcset="">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="value-wrapper">
                                                    {{currentProductItem.value | currency: 'BRL'}}
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="amount-wrapper">
                                                    {{currentProductItem.amount}}
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="total-wrapper">
                                                    {{currentProductItem.total | currency: 'BRL'}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="card">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="section-title-wrapper">
                                            <div class="title-item">
                                                Resumo
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row summerized-row">
                                    <div class="col-md-8">
                                        Valor dos produtos
                                    </div>
                                    <div class="col-md-4 item-value">
                                        {{ currentOrder.totalProductsValue | currency }}
                                    </div>
                                </div>
                                <div class="row summerized-row">
                                    <div class="col-md-8">
                                        Frete
                                    </div>
                                    <div class="col-md-4 item-value">
                                        {{ (currentOrder.deliveryValue ? currentOrder.deliveryValue : 0) | currency }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="totalized-row">
                                            <div class="row">
                                                <div class="col-md-8">
                                                    Total
                                                </div>
                                                <div class="col-md-4 item-value">
                                                    {{ currentOrder.totalValue| currency }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>

                            <div class="card">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="section-title-wrapper">
                                            <div class="title-item">
                                                Entrega
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row summerized-row">
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <form [formGroup]="this.cepFormGroup!">
                                                <input type="number" class="form-control" id="cep" placeholder="00000-000" formControlName="cep">
                                            </form>
                                        </div>
                                    </div>
                                    <div class="col-md-4 search-btn">
                                        <button class="btn btn-elementar" (click)="getNewDeliverySimulation()">Buscar</button>
                                    </div>
                                </div>
                                <div class="row" *ngIf="showSelectedOldSim && !isLoading">
                                    <div class="col-md-12">
                                        <div class="selected-delivery">
                                            <div class="logo-wrapper" >
                                                <img 
                                                    [src]="currentOrder.deliverySim?.imageLogo" 
                                                    [alt]="currentOrder.deliverySim?.name + 'logo'" 
                                                    srcset="" >
                                            </div>
                                            <div class="delivery-name-wrapper">
                                                <div class="title">
                                                    {{currentOrder.deliverySim?.name}}
                                                </div>
                                                <div class="description">
                                                    {{currentOrder.deliverySim?.description}}
                                                </div>
                                            </div>
                                            <div class="delivery-value-wrapper">
                                                {{currentOrder.deliverySim?.value | currency}}
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>

                                <div class="row" *ngIf="showNewSimSameCEPDifferentValuesMessage && !isLoading">
                                    <div class="col-md-12" style="padding: 10px;">
                                        <div class="info-message-wrapper">
                                            Ao realizar uma nova simulação pro mesmo cep, os valores podem sofrer alterações.
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="showNewSimDiffCEPMessage && deliverySimList.length != 0 && !isLoading">
                                    <div class="col-md-12" style="padding: 10px;">
                                        <div class="info-message-wrapper">
                                            Simulação abaixo para CEP: {{ deliverySimListCep }}
                                        </div>
                                    </div>
                                </div>

                                <ng-container *ngIf="!isLoading">
                                    <div class="row select-list" 
                                        *ngFor="let currentDeliverySimItem of deliverySimList; index as i" 
                                        [ngClass]="{'select-list-item': currentDeliverySimItem.isSelected }"
                                        (click)="selectShippingItem(i)"
                                    >
                                        <div class="col-md-12">
                                            <div class="selected-delivery">
                                                <div class="logo-wrapper" >
                                                    <img 
                                                        [src]="currentDeliverySimItem.imageLogo" 
                                                        [alt]="currentDeliverySimItem.name + 'logo'" 
                                                        srcset="" >
                                                </div>
                                                <div class="delivery-name-wrapper">
                                                    <div class="title">
                                                        {{currentDeliverySimItem.name}}
                                                    </div>
                                                    <div class="description">
                                                        {{currentDeliverySimItem.description}}
                                                    </div>
                                                </div>
                                                <div class="delivery-value-wrapper">
                                                    {{currentDeliverySimItem.value | currency}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>

                            <div class="card">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="section-title-wrapper">
                                            <div class="title-item">
                                                Finalizar
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row summerized-row">
                                    <div class="col-md-12 search-btn">
                                        <button class="btn btn-elementar btn-pgt" (click)="clickPayment()">Pagamento</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!currentOrder" class="row checkout-item">
                <div class="col-md-12">
                    <div class="card">
                        Não foi possível criar a ordem.
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- teste -->
</section>