/* eslint-disable prettier/prettier */

import * as hashJs from 'hash.js';

export class Login {
  email: string | null;
  password: string | null;

  constructor(email = null, password = null) {
    this.email = email;
    this.password = password;
  }

  encodePassword(): void {
    this.password = hashJs.sha256().update(this.password).digest('hex');
  }
}
