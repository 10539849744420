/* eslint-disable prettier/prettier */

import { Order } from "../app/models/Order";
import { User } from "../app/models/User";

export class Register {
    tempUser?: User;
    newUser?: User;
    orderAttached?: Order;
  }
  