import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { routes } from "./public.routes";
import { RouterModule } from '@angular/router';

import { LoginComponent } from "./login/login.component";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CheckoutComponent } from './checkout/checkout.component';
import { RegisterComponent } from "./register/register.component";


@NgModule({
  declarations: [LoginComponent, CheckoutComponent, RegisterComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  exports: [
    LoginComponent,
    CheckoutComponent, RegisterComponent
  ],
})
export class PublicModule { }
