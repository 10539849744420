/* eslint-disable prettier/prettier */
import { BaseModel } from './BaseModel';

export class ElementarDate extends BaseModel {
  number: number;

  constructor(number: number | null = null){
    super();

    if (number == null) {
      const date = new Date();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      // console.log(`${month}  ${year}`);

      
      this.number = parseInt(`${year}${month >= 10 ? month : '0' + month}01`, 10);
    } else {
      this.number = number;
    }
  
  }

  getPreviousMonth(): ElementarDate | null {
    // [TODO] Special handle for feburary 29 days and general end of month calc
    if (!this.number) {
    return null;
    }

    const stringNumberFormatDate = this.number.toString();

    const date = {
      year: `${stringNumberFormatDate[0]}${stringNumberFormatDate[1]}${stringNumberFormatDate[2]}${stringNumberFormatDate[3]}`,
      month: `${stringNumberFormatDate[4]}${stringNumberFormatDate[5]}}`,
      day: `${stringNumberFormatDate[6]}${stringNumberFormatDate[7]}`,
    }

    let prevMonthValue = parseInt(date.month, 10) - 1;
    let prevYearValue = parseInt(date.year, 10);

    if (prevMonthValue < 1) {
      prevMonthValue = 12;
      prevYearValue -= 1;
    } 

    let strigfiedMonth = null;
    if(prevMonthValue < 10) {
      strigfiedMonth = `0${prevMonthValue}`;
    } else {
      strigfiedMonth = prevMonthValue;
    }
    
    return new ElementarDate(parseInt(`${prevYearValue}${strigfiedMonth}${date.day}`, 10));
  }
 
  getNextMonth(): ElementarDate | null {
    // [TODO] Special handle for feburary 29 days and general end of month calc
    if (!this.number) {
    return null;
    }

    const date = this.splitDateContent();

    let nextMonthValue = parseInt(date.month, 10) + 1;
    let nextYearValue = parseInt(date.year, 10);

    if (nextMonthValue > 12) {
    nextMonthValue = 1;
    nextYearValue += 1;
    } 

    let strigfiedMonth = null;
    if(nextMonthValue < 10) {
    strigfiedMonth = `0${nextMonthValue}`;
    } else {
    strigfiedMonth = nextMonthValue;
    }

    // console.log(`ElementarDate => getNextMonth => strigfiedMonth :: ${strigfiedMonth}`);
    
    return new ElementarDate(parseInt(`${nextYearValue}${strigfiedMonth}${date.day}`, 10));
  }

  getFormatted(format: 'dd/mm/yyyy' | 'dd/mm/YY' | 'dd/MM/yyyy' | 'dd/MM/YY' = 'dd/mm/yyyy'): string{
    const parsedNumber = `${this.number}`;
    switch (format) {
      case 'dd/mm/yyyy':
        return `${parsedNumber[6]}${parsedNumber[7]}/${parsedNumber[4]}${parsedNumber[5]}/${parsedNumber[0]}${parsedNumber[1]}${parsedNumber[2]}${parsedNumber[3]}`;
    
      case 'dd/mm/YY':
        return `${parsedNumber[6]}${parsedNumber[7]}/${parsedNumber[4]}${parsedNumber[5]}/${parsedNumber[2]}${parsedNumber[3]}`;

      case 'dd/MM/yyyy':
        return `${parsedNumber[6]}${parsedNumber[7]}/${this.getWrittenMonth(`${parsedNumber[4]}${parsedNumber[5]}`)}/${parsedNumber[0]}${parsedNumber[1]}${parsedNumber[2]}${parsedNumber[3]}`;
    
      case 'dd/MM/YY':
        return `${parsedNumber[6]}${parsedNumber[7]}/${this.getWrittenMonth(`${parsedNumber[4]}${parsedNumber[5]}`)}/${parsedNumber[2]}${parsedNumber[3]}`;
      
      default:
        return `${parsedNumber[6]}${parsedNumber[7]}/${parsedNumber[4]}${parsedNumber[5]}/${parsedNumber[0]}${parsedNumber[1]}${parsedNumber[2]}${parsedNumber[3]}`;
        break;
    }
  }

  addADay(): void {
    const date = this.splitDateContent();
    
  }

  addDays(amount: number): void{

  }

  private splitDateContent(): any {
    const stringNumberFormatDate = this.number.toString();
    return {
      year: `${stringNumberFormatDate[0]}${stringNumberFormatDate[1]}${stringNumberFormatDate[2]}${stringNumberFormatDate[3]}`,
      month: `${stringNumberFormatDate[4]}${stringNumberFormatDate[5]}}`,
      day: `${stringNumberFormatDate[6]}${stringNumberFormatDate[7]}`,
     }
  }

  private getWrittenMonth(month: string): string {
    switch (month) {
      case '01':
        return 'Janeiro';

      case '02':
        return 'Fevereiro';

      case '03':
        return 'Março';
        
      case '04':
        return 'Abril';
        
      case '05':
        return 'Maio';

      case '06':
        return 'Junho';
        
      case '07':
        return 'Julho';

      case '08':
        return 'Agosto';

      case '09':
        return 'Setembro';
    
      case '10':
        return 'Outubro';

      case '11':
        return 'Novembro';

      case '12':
        return 'Dezembro';

      default:
        return 'UNDEFINED';
    }
  }
}

