import { Routes } from '@angular/router';
import { LoginComponent } from "./login/login.component";
import { CheckoutComponent } from './checkout/checkout.component';
import { RegisterComponent } from "./register/register.component";

export const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'checkout/:token/:order',
    component: CheckoutComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  }
];
