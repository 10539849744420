/* eslint-disable prettier/prettier */

import { BaseModel } from "./BaseModel";
import { User } from "./User";

export class Address extends BaseModel {
    aditional?: string;
    cep?: number;
    city?: string;
    complement?: string;
    lat?: string;
    long?: string;
    neighborhood?: string;
    nickname?: string;
    number?: string;
    owner?: User;
    street?: string;
}
