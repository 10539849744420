<div class="login-wrapper">
    <div class="card">
      <div class="card-body login-card-body">
        <div class="form-wrapper">
          <form class="current-form" [formGroup]="this.loginForm!" (ngSubmit)="this.onSubmit()">
            <div class="input-group mb-3">
              <input type="email" class="form-control" placeholder="Email" formControlName="email">
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-envelope"></span>
                </div>
              </div>
            </div>
            <div class="input-group mb-3">
              <input type="password" class="form-control" placeholder="Password" formControlName="password">
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-lock"></span>
                </div>
              </div>
            </div>
            <div class="row">
              <!-- <div class="col-8">
                <div class="icheck-primary">
                  <input type="checkbox" id="remember" formControlName="remember">
                  <label for="remember">
                    Lembrar
                  </label>
                </div>
              </div> -->
              <!-- /.col -->
              <div class="col-12">
                <!-- <app-loader *ngIf="this.loading"></app-loader> -->
                <!-- *ngIf="!this.loading" -->
                <button  type="submit" class="btn btn-primary info-buttom-elementar btn-block">Entrar</button>
              </div>
              <!-- /.col -->
            </div>
          </form>
          <div class="related-links" >
            <div class="related-link-item" (click)="clickRedirect('register')">
              Cadastrar
            </div>
            <!-- <div class="related-link-item">
              Esqueci minha senha
            </div> -->
          </div>
        </div>
        <div class="login-splitter">

        </div>
        <div class="login-logo">
          <img class="logo-item" width="100%" src="../../../assets/images/NO_BG_BANNER_LOGO_LIGHT.png" alt="Fotons">
        </div>
      </div>
    </div>
</div>

