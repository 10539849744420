import { Injectable } from '@angular/core';

import { environment } from "../../../../environments/environment";
import { HttpClient } from '@angular/common/http';
import { Login } from '../../../../interactionModels/Login';
import { Observable } from 'rxjs';
import { Response } from '../../../../app/models';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private httpClient: HttpClient) { }

  loginUser(loginContent: Login): Observable<Response<any>> {
    return this.httpClient.post<Response<any>>(`${environment.apiUrl}auth/login`, loginContent);
  }
}
