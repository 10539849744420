import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Order } from '../../../app/models/Order';
import { Response } from '../../../app/models/Response';
import { environment } from '../../../environments/environment';
import { SimResponse } from '../../../app/models/SimResponse';
import { InquiryResponse } from '../../../app/models';


@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  constructor(private httpClient: HttpClient){}

  /**
   * Description
   * @param {any} orderExtIt:string
   * @param {any} userToken:string
   * @returns {any}
   */
  getOrderByExtId(orderExtIt: string, userToken: string): Observable<Response<Order>> {
    console.log('CheckoutService => getOrderByExtId');
    
    let headers = new HttpHeaders(); // create header object
    headers = headers.append('TempUser', userToken); // add a new header, creating a new object
    return this.httpClient.get<Response<Order>>(`${environment.apiUrl}checkout/order/${orderExtIt}`, { headers });
  }

  /**
   * Description
   * @param {any} order:Order
   * @returns {any}
   */
  getShippingSimulation(order: Order): Observable<InquiryResponse<SimResponse>> {
    let productAmount = 0;
    order.productsList?.forEach(curentProductItem => {
      productAmount += curentProductItem.amount!;
    });

    return this.httpClient.post<InquiryResponse<SimResponse>>(`${environment.apiUrl}delivery/simulation`, {
      toCEP: order.deliverySim?.cepDestination,
      productsAmount: productAmount,
      valueCost: order.totalProductsValue,
      productItemCost: order.productsList![0].value
    }); 
  }

  updateSimByOrder(order: Order): Observable<Response<any>> {
    return this.httpClient.put<Response<any>>(`${environment.apiUrl}delivery/order-simulation`, order); 
  }
}
