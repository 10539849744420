/* eslint-disable prettier/prettier */
export enum ComparerType {
  EQUALS,
  NOTEQUALS,
  LESSTHAN,
  GREATERTHAN,
  LESSTHANOREQUALTO,
  GREATERTHANOREQUALTO,
  LIKE,
  BETWEEN,
  ISNULL,
  ISNOTNULL,
  IN,
  NOTIN,
}
