/* eslint-disable prettier/prettier */

import { BaseModel } from "./BaseModel";
import { User } from "./User";

export class Password extends BaseModel {
    passwordData?: string;
    uncryptedData?: string;
    owner?: User;
    expiresAt?: number;
}
