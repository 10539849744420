/* eslint-disable prettier/prettier */
import { ItemStatusEnum } from '../enums';
import { ElementarDate } from './ElementarDate';
import { User } from './User';

export class BaseModel {
  id?: number | undefined;
  externalId: string | undefined;

  createdAt: ElementarDate | undefined;
  updatedAt: ElementarDate | undefined;
  deletedAt: ElementarDate | undefined;

  createdBy: User | undefined;
  updatedBy: User | undefined;
  deletedBy: User | undefined;

  status: ItemStatusEnum | undefined;

  constructor(
    id: number | null = null,
    externalId: string | null = null,
    createdAt: ElementarDate | null = null,
    updatedAt: ElementarDate | null = null,
    deletedAt: ElementarDate | null = null,
    createdBy: User | null = null,
    updatedBy: User | null = null,
    deletedBy: User | null = null,
    status: ItemStatusEnum | null = null,
  ) {
    if (id) {
      this.id = id;
    }
    if (externalId) {
      this.externalId = externalId;
    }

    if (createdAt) {
      this.createdAt = createdAt;
    }
    if (updatedAt) {
      this.updatedAt = updatedAt;
    }
    if (deletedAt) {
      this.deletedAt = deletedAt;
    }

    if (createdBy) {
      this.createdBy = createdBy;
    }
    if (updatedBy) {
      this.updatedBy = updatedBy;
    }
    if (deletedBy) {
      this.deletedBy = deletedBy;
    }

    if (status) {
      this.status = status;
    }
  }
}
